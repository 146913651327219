export const heightCarouselFeature = ({width}) => {
   console.log('heightCarouselFeature width: ', width);
    if(width >= 0 && width < 450 )
       return {
               height:605,
               fontSizeH3: "18px",
               fontSizeP: "16px",
               lineHeight: "30px"
             };
   else if( width >= 450 &&  width < 576){
     return {
               height:605,
               fontSizeH3: "18px",
               fontSizeP: "16px",
               lineHeight: "30px"
             };
   }
   else if(width >= 576 && width < 950){
     return {
               height:590,
               fontSizeH3: "1.5rem",
               fontSizeP: "1.2rem",
               lineHeight: "40px"
             };
   }
   else if(width >= 950){
    return {
              height:400,
              fontSizeH3: "1.5rem",
              fontSizeP: "1.3rem",
              lineHeight: "40px"
            };
  }
};


export const heightCarouselAboutKchat = ({width}) => {
    if(width >= 0 &&  width < 450  )
       return {
                height: 390,
                fontSizeH3: "18px",
                fontSizeP: "16px",
                lineHeight: "30px"
              };
   else if( width >= 450 &&  width< 576){
     return {
              height:390,
              fontSizeH3: "18px",
              fontSizeP: "16px",
              lineHeight: "30px"
            };
   }
   else if(width >= 576 &&  width < 950){
     return {
              height:360,
              fontSizeH3: "1.5rem",
              fontSizeP: "1.2rem",
              lineHeight: "40px"
            };
   }
   else if(width >= 950){
    return {
             height:340,
             fontSizeH3: "1.5rem",
             fontSizeP: "1.2rem",
             lineHeight: "40px"
           };
  }
};

export const heightCarouselWhyKchat = ({width}) => {
    if(width >= 0 &&  width < 450)
    return {
             section: {
              paddingRight: 0,
             },
             div1: {
                fontSizeH4: "16px",
                fontSizeP: "16px",
                lineHeight: "25px"
             },
             height: 820,
           };
    else if( width >= 450 &&  width < 576){
      return {
                section: {
                    paddingRight: 0,
                },
               div1: {
                  fontSizeH4: "16px",
                  fontSizeP: "16px",
                  lineHeight: "30px"
               },
               height: 850,
                };
        }
      else if(width >= 576  &&  width < 950){
      return {
              section: {
                paddingRight: 0,
            },
          div1: {
              fontSizeH4: "16px",
              fontSizeP: "16px",
              lineHeight: "30px"
          },
          height: 850,
            };
        }
      else if(width >= 950 &&  width < 1000){
      return {
            section: {
                paddingRight: 0,
            },
           div1: {
              fontSizeH4: "1.5rem",
              fontSizeP: "1.2rem",
              lineHeight: "40px"
           },
           height: 850,
            };
        }
      else if(width >= 1000 &&  width < 1250){
          return {
                section: {
                    paddingRight: 0,
                },
               div1: {
                  fontSizeH4: "1.5rem",
                  fontSizeP: "1.2rem",
                  lineHeight: "40px"
               },
               height: 720,
                };
            }
      else if(width >= 1250){
          return {
                section: {
                    paddingRight: 0,
                },
               div1: {
                  fontSizeH4: "1.5rem",
                  fontSizeP: "1.2rem",
                  lineHeight: "40px"
               },
               height: 720,
                };
            }
};

export const heightCarouselAccordion = ({width}) => {
    if(width >=0 && width <= 375 )
       return {
                height: 920,
              };
   else  if(width > 375 &&  width < 450)
      return {
              height: 840,
            };
   else if( width >= 450 &&  width < 950){
     return {
              height:780,
            };
   }
   else if(width >= 950 &&  width < 1550){
     return {
              height:780,
            };
   }
   else if(width >= 1550){
    return {
             height:850,
           };
  }
};

export const heightCarouselAboutUs = ({width}) => {
    if(width >= 0 &&  width < 450 )
       return {
                height: 550,
                fontSizeP: "18px",
              };
   else if( width >= 450 &&  width < 576){
     return {
              height:550,
              fontSizeP: "18px",
            };
   }
   else if(width >= 576){
     return {
              height:380,
              fontSizeP: "1.5rem",
            };
   }
};