// extracted by mini-css-extract-plugin
export var Carousel = "Main2-module--Carousel--GpQN5";
export var home2_div_center = "Main2-module--home2_div_center--7+dw-";
export var home2_img = "Main2-module--home2_img--u7x6+";
export var home2_img1 = "Main2-module--home2_img1--g5FiI";
export var home2_img2 = "Main2-module--home2_img2--gdxX+";
export var home2_main2 = "Main2-module--home2_main2--L39Ra";
export var home2_main2_section = "Main2-module--home2_main2_section--A3yje";
export var home2_main2_section_div = "Main2-module--home2_main2_section_div--eiBIG";
export var home2_section_title = "Main2-module--home2_section_title--xsqMQ";
export var home2_title_h2 = "Main2-module--home2_title_h2--PajbQ";
export var home2_title_p = "Main2-module--home2_title_p--be1yh";