import React from 'react'
import * as classes from  './Accordeon.module.css';
import ItemAccordion from './ItemAccordion'; 

const Accordion = ({initialAccordionData}) => { 

  const [accordionData, setAccordionData] = React.useState(initialAccordionData);
  
  const updateActive = ({title, active}) => {

      if(!active){
        setAccordionData(current => current.map(item => ({...item, active: false})))
      }
      
      setAccordionData(current => current.map(item => {
          if(item.title === title){
            return {...item, active: !active};
          }
          return item;
        }));
    };

  return (
        <div className={classes.accordion}>
        {accordionData.map(({ title, content, active }) => (
        <ItemAccordion key={title} title={title} content={content} active={active} updateActive={updateActive} />
        ))}
    </div>
  )
}

export default Accordion