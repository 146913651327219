// extracted by mini-css-extract-plugin
export var Carousel = "Footer2-module--Carousel--R20cj";
export var home2_footer2 = "Footer2-module--home2_footer2--rYLaO";
export var home2_footer2_a = "Footer2-module--home2_footer2_a--JiOz1";
export var home2_footer2_div = "Footer2-module--home2_footer2_div--RM3Id";
export var home2_footer2_div_about = "Footer2-module--home2_footer2_div_about--8+SXX";
export var home2_footer2_div_link = "Footer2-module--home2_footer2_div_link--xyXBo";
export var home2_fotter2_form = "Footer2-module--home2_fotter2_form--lPZxI";
export var home2_fotter2_form_div = "Footer2-module--home2_fotter2_form_div--5LUyT";
export var home2_fotter2_form_img = "Footer2-module--home2_fotter2_form_img--sP-ZX";
export var home2_fotter2_form_input = "Footer2-module--home2_fotter2_form_input--qBRGY";
export var home2_fotter2_form_textarea = "Footer2-module--home2_fotter2_form_textarea--mwPYU";
export var home2_fotter2_img = "Footer2-module--home2_fotter2_img--ngsDP";
export var home2_fotter2_img1 = "Footer2-module--home2_fotter2_img1--4Eu15";
export var home2_fotter2_mailto = "Footer2-module--home2_fotter2_mailto--BpzBJ";
export var home2_fotter2_p = "Footer2-module--home2_fotter2_p--+u7pr";
export var home2_fotter2_p3 = "Footer2-module--home2_fotter2_p3--ktBsA";
export var home2_fotter2_p4 = "Footer2-module--home2_fotter2_p4--Fvc+P";
export var home2_fotter2_title = "Footer2-module--home2_fotter2_title--eWrNR";
export var home2_fotter_rectangle21 = "Footer2-module--home2_fotter_rectangle21---rEdD";
export var home2_fotter_rectangle5 = "Footer2-module--home2_fotter_rectangle5--Sd0U0";