// extracted by mini-css-extract-plugin
export var home_backg_link = "Header-module--home_backg_link--+VfiH";
export var home_backg_logo = "Header-module--home_backg_logo--GX548";
export var home_backg_rectangle3 = "Header-module--home_backg_rectangle3--s7Bzv";
export var home_chat = "Header-module--home_chat--EXEfp";
export var home_chat_img = "Header-module--home_chat_img--ehVpc";
export var home_chat_img1 = "Header-module--home_chat_img1--yNLwV";
export var home_chat_img_container = "Header-module--home_chat_img_container--XVIxI";
export var home_chat_p = "Header-module--home_chat_p--vKtpR";
export var home_container_img_boys = "Header-module--home_container_img_boys--ZV3xH";
export var home_header = "Header-module--home_header--Fu9QK";
export var home_header_divider = "Header-module--home_header_divider--KmOZg";
export var home_img = "Header-module--home_img--udXiH";
export var home_img_boys = "Header-module--home_img_boys--31+gi";
export var home_rect_container = "Header-module--home_rect_container--h572T";
export var home_rectangle3_container = "Header-module--home_rectangle3_container--9d6np";
export var home_rectangle3_text = "Header-module--home_rectangle3_text--z-c7t";
export var home_title_h1 = "Header-module--home_title_h1--DAexL";