import React from 'react'
import { classes } from './styles.js'
import  * as classNames from './Carousel.module.scss';
import { StaticImage } from 'gatsby-plugin-image';

const ItemFeatures = ({listItem, height, fontSizeH3, fontSizeP, lineHeight}) => {
    
    return (
        <div style={{...classes.home_main1_group37_container, ...{height: height}}}>
            <div>
              <p className={classNames.feature_subtitle} style={{...classes.home_group37_title_h3, ...{fontSize: fontSizeH3, lineHeight: lineHeight}}}>{listItem.title3}</p>
              <p style={{...classes.home_group37_p, ...{fontSize: fontSizeP, lineHeight: lineHeight}}}>{listItem.text}</p>
                {
                  listItem.list?.length > 0 && listItem.list.map( (item) => ( <p key={item} style={{...classes.home_group37_p, ...{fontSize: fontSizeP, lineHeight: lineHeight}}}>{item}</p>))
                }
            </div>
            <div><StaticImage placeholder= {`none`}  loading={`eager`} src='../../images/mobile/Asset2.png' alt={`iPhone`} style={classes.home_main1_group37_img} className={classNames.main1_img_group37}/></div>
        </div>
      )
}

export default ItemFeatures