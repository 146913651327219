export const useExportList = () => {

    const listItem_Features = [
        {
          title1: "Features",
          title3: "Messaging made fun.",
          text: "Chatting to loved ones and creative expression has never been this fun! With kChat, kids can:",
          list: [ 
                  "Send text and voice messages", 
                  "Share animated stickers and photos",
                  "Doodle to their hearts’ content",
                ],
        },
        {
          title1: "Features",
          title3: "Messaging made safe.",
          text: "Kids are 100% safe messaging on kChat. ",
          list: [
            "Kids don’t need to have a phone number",
            "Parents have full control over who their kids talk to"
          ],
        },
        {
          title1: "Features",
          title3: "Messaging made easy.",
          text: "kChat is specifically designed to be used and enjoyed by kids. Easy navigation, simplicity in design, and clever use of icons make kChat suitable for children aged 5 and up. ",
          list: [],
        },
      ];
    const listItem_AboutKchat = [
        {
            title: "Testimonial 1:",
            text: "“With kChat, I have complete peace of mind knowing that my kids can message their friends or our family and they are always 100% safe. kChat works in such a way that it’s impossible for strangers or unwanted people to contact my girls.”",
            author: "Phillip, father of Maria (10) and Chloe (6)",
        },
        {
            title: "Testimonial 2:",
            text: "“kChat is great for my daughter because she’s only been reading and writing a year. kChat helps her to practice these skills in a fun way, but we also have the option to communicate via voice messages.”",
            author: "Dianna, mother of Kimberly (7)",
        },
        {
            title: "Testimonial 3:",
            text: "“I really like kChat. I wasn’t allowed to text my friends before, but now I can talk to them whenever I want. I’ve got a group with lots of friends, and I like that we can all talk together.”",
            author: "Seth (9)",
        }
    ];
    const listItem_WhyKchat = [
        {
            title1: "Why kChat App?",
            title2: "Designed for Kids ",
            text: "Kids have the freedom and independence to chat with friends and close family.",
            list: [
                "Simple and attractive <b>kid-friendly</b> user interface",
                "Quick communication via <b>text messages</b>",
                "Even young kids can communicate via <b>voice messages</b>",
                "Take and send <b>photos</b> or share images from your gallery",
                "Fun expression using <b>animated stickers</b>",
                "Get creative with <b>sketches and drawings</b>",
                "<b>No in-app ads</b>",
            ],
        },
        {
            title1: "Why kChat App?",
            title2: "Designed by Parents ",
            text: "Parents can finally rest assured that their kids are safe messaging on kChat.",
            list: [
                "<b>Complete parental control</b> from a password-protected settings page",
                "<b>Only parents’ phone numbers</b> are used",
                "<b>Only parents</b> can accept friend requests",
                "<b>Only parents</b> can add friends",
                "Friends can only be added if they belong to a <b>known group</b>",
                "Easy to <b>stay in touch with kids</b> when they are at school or with friends",
                "<b>No download or subscription fees</b>",
            ],
        }
    ];
    const listItem_AccordionData = [
      [{
        title1: "Using kChat", 
        title: "How old must kids be to use kChat?",
        content: "There is no age restriction, and parents can use their own discretion whether to allow their kids to use the app. As a guideline, we suggest that kChat be used by kids aged 5 and up. As kids can send photos, voice messages, and stickers, children can use kChat before learning to read.",
        active: true,
       },
       {
        title1: "Using kChat",
        title: "Does my child need a phone number to use kChat?",
        content: "No! kChat only requires the parent’s or guardian’s phone number to register.",
        active: false,
       },
       {
        title1: "Using kChat",
        title: "Do kids need their own devices to use kChat?",
        content: "No. Kids can safely use their parents’ smartphone or tablet to use the app, as the settings area is password protected. ",
        active: false,
       },
       {
        title1: "Using kChat",
        title: "Can I add more than one child to my account?",
        content: "Yes. Any number of kids can be added to a parent’s account. Kids just need to select their profile when entering.",
        active: false,
       },
       {
        title1: "Using kChat",
        title: "What can kids do on the app?",
        content: "As with any messaging platform, they can send text messages. Text is slightly larger than standard messaging platforms, which is easier for children to read. They can also record and share audio messages. Photos can be taken in-app, or photos and images from the device’s gallery can be shared. There is a wide selection of fun animated stickers and emojis for kids to choose from. Additionally, kids can draw, doodle, or sketch on a drawing board and then share their artwork with contacts.",
          active: false,
        }],
       [{
        title1: "Security",
        title: "Can I control who contacts my child?",
        content: "Yes, parents have complete control. Only parents are able to send or accept invites. Moreover, people must belong to a known group before they can be added or accepted as a contact. This means that strangers have absolutely no way of contacting your child.",
        active: true,
       },
       {
        title1: "Security",
        title: "Can I view my child’s messages?",
        content: "Yes, as long as parents have added their children’s profiles to their accounts. All you have to do is select the child’s profile to view their chat history.",
        active: false,
       },
       {
        title1: "Security",
        title: "Is my data secure?",
        content: "Yes. Your details, kids’ profiles, groups, messages, and media are all encrypted. Furthermore, third parties will never have access to your data. For further details, see our Privacy Policy.",
        active: false,
       }],
       [{
        title1: "Fees",
        title: "Do I need to pay to use kChat?",
        content: "No. kChat is free to download and use.",
        active: true,
       },
       {
        title1: "Fees",
        title: "Is there a catch?",
        content: "Thankfully not! We will not be introducing subscription fees now or any time in the future. We can also assure you that there are no in-app ads.",
        active: false,
       }],
       [{
        title1: "Other questions",
        title: "Where can I download kChat?",
        content: "You can download kChat from the <a id='App1'>App Store</a> or <a id='Google1'>Google Play</a>.",
        active: true,
       },
       {
        title1: "Other questions",
        title: "Is kChat available in any other languages?",
        content: "Yes. kChat is available in English, Spanish, and Hebrew.",
        active: false,
       },
       {
        title1: "Other questions",
        title: "What happens if I forget my code?",
        content: "You will be prompted to reset your code. An OTP (one-time password) will be sent to your registered phone number. Once you have verified your account by entering this password, you can select a new code.",
        active: false,
       }],
    ];
    const listItem_AboutUs = [
      {
        text: "As a mother of three, I understand that my kids need the freedom and independence to be able to stay in touch with their friends and family. However, as a parent with a computer science background, I’m also well aware of the dangers and inside risks associated with social networks. What’s worse is that children are often the most targeted and yet most vulnerable to these threats.",
      },
      {
        text: "kChat was created with these concerns in mind. It offers kids a completely safe platform to chat with friends—and stay in contact with us parents too! The user interface is simple and easy for kids to navigate while also allowing them to express themselves creatively and in a fun and exciting way. There’s certainly nothing boring about this app! ",
      },
      {
        text: "While working on this project, COVID-19 hit us all and turned us into socially distanced and isolated individuals. This made kChat’s potential to make a difference so much greater. The messaging app allows children to stay in close contact with their friends and relatives they no longer get to see and stay up to date with their teachers and school work.",
      },
      {
        text: "But most importantly, parents have peace of mind knowing that their kids are safe and protected at all times. Children never have to give out their phone numbers, and they can only talk to people we approve. It’s definitely a win for both parents and kids!",
      }
    ];
  return {
    listItem_Features,
    listItem_AboutKchat,
    listItem_WhyKchat,
    listItem_AccordionData,
    listItem_AboutUs
  }
}
