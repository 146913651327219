import React from 'react'
import { classes } from './styles.js'
import { useWidth } from '../../hook/useWidth';
import * as cssClassName from './Carousel.module.scss';
import { createMarkup } from '../../helpers/common';
import { StaticImage } from 'gatsby-plugin-image';

const ItemWhyKchat = ({listItem, section, div1, div2}) => {

    const { width } = useWidth();

  return (
            <section style={{
                             ...classes.home_fotter_rectangle13_container, 
                             ...section, 
                             ...{boxShadow: width < 1000  ? "0px 3.51638px 9.67004px rgba(98, 66, 0, 0.05)" : "none"},
                             ...{
                                paddingLeft: width < 1000  ? "0rem" : "2rem", 
                                paddingRight: width < 1000  ? "0rem" : "2rem",
                              }
                             }}>
                <div style={classes.home_footer_container}>
                    <div style={classes.home_footer_card}>
                        <h4 style={{...classes.home_footer_card_h3, ...{ fontSize: div1.fontSizeH4, lineHeight: div1.lineHeight }}}>{listItem.title2}</h4>
                        <p style={{...classes.home_footer_p, ...{ fontSize: div1.fontSizeP, lineHeight: div1.lineHeight }}}>{listItem.text}</p>
                    </div>
                    <div >
                        {
                            listItem.list?.length > 0 && listItem.list.map( (item) => ( 
                                                                                        <p 
                                                                                            key={item} 
                                                                                            style={{...classes.home_footer_li, ...{ fontSize: div1.fontSizeP, lineHeight: div1.lineHeight }}}
                                                                                            dangerouslySetInnerHTML={createMarkup({content: item})}
                                                                                        />
                                                                                        )
                                                                            )
                        }
                    </div>
                </div>
                <div className={cssClassName.footer_container}>
                    <div style={classes.home_footer_images} className={cssClassName.footer_link_container}>
                        <a  
                            href={ 
                                width < 950  
                                            ?
                                            "https://apps.apple.com/us/app/kchat-the-key-to-chat-safely/id1510149456"
                                            :
                                            "https://apps.apple.com/us/app/kchat-the-key-to-chat-safely/id1510149456"
                                }
                            alt="App Store">
                            <StaticImage placeholder= {`none`}  loading={`eager`} src='../../images/mobile/App_Store.svg' alt="App Store" className={cssClassName.home_chat_img1}  />
                        </a>
                        <a 
                            href={ 
                                width < 950  
                                            ?
                                            "https://play.google.com/store/apps/details?id=com.knesis.kchat"
                                            :
                                            "https://play.google.com/store/apps/details?id=com.knesis.kchat"
                                }
                                alt="Google Play"
                                style={{marginLeft: "0.5rem"}}
                        >
                            <StaticImage placeholder= {`none`}  loading={`eager`} src='../../images/mobile/google-play-badge.png' alt="Google Play" className={cssClassName.home_chat_img} />
                        </a>
                    </div>
                    <div className={cssClassName.footer_girls_container}>
                        <StaticImage placeholder= {`none`}  loading={`eager`} src='../../images/mobile/girls.png' alt="girls" className={cssClassName.footer_girls_img}/>
                    </div>
                </div> 
            </section>
  )
}

export default ItemWhyKchat