import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as classes from './Footer2.module.scss';

import { useWidth } from '../../hook/useWidth';
import { createMarkup } from '../../helpers/common';

const Footer2 = () => {

  const { width } = useWidth(); 

  React.useEffect(() => {
    if(typeof window !== "undefined"){
      window.document.getElementById('mailto').href = "mailto:support@kchat.fun";
      window.document.getElementById('mailto').className = classes.home2_fotter2_mailto;
    }
  },[])
  
  return (
        <footer className={classes.home2_footer2}>
               <section className={classes.home2_fotter_rectangle5}>
                <div className={classes.Carousel}>
                    <div>
                      <h2 className={classes.home2_fotter2_title}>Contact Us</h2>
                    </div>
                    <div>
                      <p className={classes.home2_fotter2_p}>
                         Have a question, comment, or suggestion? We’d love to hear from you.
                      </p>
                    </div>
                    <div>
                      <p className={classes.home2_fotter2_p} dangerouslySetInnerHTML={createMarkup({content: `You can send an email to <a id='mailto'>support@kchat.fun</a>.`})} />
                    </div>
                </div>
               </section>
               <section className={classes.home2_fotter_rectangle21}>
               <div className={classes.Carousel}>
                      <div id="download">
                        <p className={classes.home2_fotter2_p3}>
                          Safe and fun chatting is just one step away.
                        </p>
                      </div>
                      <div>
                        <p className={classes.home2_fotter2_p4}>
                          Download Now!
                        </p> 
                      </div>
                      <div className={classes.home2_footer2_div}>
                          <a 
                            className={classes.home2_footer2_a}
                            href={ 
                              width < 950  
                                          ?
                                          "https://apps.apple.com/us/app/kchat-the-key-to-chat-safely/id1510149456"
                                          :
                                          "https://apps.apple.com/us/app/kchat-the-key-to-chat-safely/id1510149456"
                              }
                            alt="App Store">
                            <StaticImage placeholder= {`none`}  loading={`lazy`}  src='../../images/mobile/App_Store.svg' alt="App_Store" className={classes.home2_fotter2_img1}/>
                          </a>
                          <a
                            className={classes.home2_footer2_a}
                            href={ 
                              width < 950  
                                          ?
                                          "https://play.google.com/store/apps/details?id=com.knesis.kchat"
                                          :
                                          "https://play.google.com/store/apps/details?id=com.knesis.kchat"
                                }
                              alt="Google Play">
                              <StaticImage placeholder= {`none`}  loading={`lazy`}  src='../../images/mobile/google-play-badge.png' alt="Google_Play" className={classes.home2_fotter2_img}/>
                          </a>
                      </div>
                      <div className={classes.home2_footer2_div_link}>
                        <Link to={`/privacy`}>Privacy Policy</Link>
                        <Link to={`/terms`}>Terms of Use</Link>
                      </div>
                      <div style={{width: "100%"}}>
                          <div id="AboutUs" className={classes.home2_footer2_div_about}>
                                <Link to={`/about`}>Our Story</Link>
                          </div>
                      </div> 
                    </div>
               </section>
        </footer> 
  )
}

export default Footer2