// extracted by mini-css-extract-plugin
export var Carousel = "Main-module--Carousel--He7aI";
export var Carousel1 = "Main-module--Carousel1--X7-cZ";
export var Carousel2 = "Main-module--Carousel2--x4jfa";
export var home_backg_logo = "Main-module--home_backg_logo--oIQUK";
export var home_group37_title = "Main-module--home_group37_title--wSZxr";
export var home_group37_title_h1 = "Main-module--home_group37_title_h1--GxRHd";
export var home_img_boys = "Main-module--home_img_boys--us7C9";
export var home_main1 = "Main-module--home_main1--egCbj";
export var home_main1_aboutCkat = "Main-module--home_main1_aboutCkat--CWwer";
export var home_main1_aboutCkat_div = "Main-module--home_main1_aboutCkat_div--0IU6J";
export var home_main1_aboutCkat_h1 = "Main-module--home_main1_aboutCkat_h1--2poSy";
export var home_main1_gettingCkat = "Main-module--home_main1_gettingCkat--lTBAV";
export var home_main1_gettingCkat_container = "Main-module--home_main1_gettingCkat_container--dJmZo";
export var home_main1_gettingCkat_div = "Main-module--home_main1_gettingCkat_div--yG6nC";
export var home_main1_gettingCkat_h1 = "Main-module--home_main1_gettingCkat_h1--vvCwJ";
export var home_main1_gettingCkat_ol = "Main-module--home_main1_gettingCkat_ol--kijdH";
export var home_main1_group37 = "Main-module--home_main1_group37--nFYlG";
export var home_main1_group37_container = "Main-module--home_main1_group37_container--1kLFF";
export var home_main1_group37_img = "Main-module--home_main1_group37_img--XJhXM";
export var home_main1_group37_img2 = "Main-module--home_main1_group37_img2--ArLu0";
export var home_main1_speedDial = "Main-module--home_main1_speedDial--lB4HE";
export var main1_Group25 = "Main-module--main1_Group25--rN5DA";
export var main1_section_carouselFeature = "Main-module--main1_section_carouselFeature--i+BU6";
export var main_iPhonePro2 = "Main-module--main_iPhonePro2--K+A7w";
export var main_iPhonePro2_container = "Main-module--main_iPhonePro2_container--rS1Er";
export var section_container_title = "Main-module--section_container_title--Mff5e";