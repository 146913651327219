// extracted by mini-css-extract-plugin
export var Carousel = "Footer-module--Carousel--ekN4w";
export var home_footer_card = "Footer-module--home_footer_card--e8P5i";
export var home_footer_card_h3 = "Footer-module--home_footer_card_h3--Hpv9I";
export var home_footer_card_text = "Footer-module--home_footer_card_text--bXdRr";
export var home_footer_images = "Footer-module--home_footer_images--oVWuE";
export var home_footer_img = "Footer-module--home_footer_img--QNUUm";
export var home_footer_title = "Footer-module--home_footer_title--4XLl6";
export var home_footer_title_h2 = "Footer-module--home_footer_title_h2--LJ0TE";
export var home_fotter_container = "Footer-module--home_fotter_container--iYcfM";
export var home_fotter_container_all = "Footer-module--home_fotter_container_all--T6OHl";
export var home_fotter_rectangle13 = "Footer-module--home_fotter_rectangle13--0znpQ";
export var home_fotter_rectangle13_container = "Footer-module--home_fotter_rectangle13_container--soAFe";
export var home_group37_title = "Footer-module--home_group37_title--cadTV";
export var home_group37_title_h1 = "Footer-module--home_group37_title_h1--TKnXS";
export var home_img_girls = "Footer-module--home_img_girls--NRAki";
export var home_title_h2 = "Footer-module--home_title_h2--NCjDL";