import React from 'react'
import * as classes from './Accordeon.module.css';
import ClassNames from 'classnames';
import { createMarkup } from '../../helpers/common';
import { useWidth } from '../../hook/useWidth';

const ItemAccordion = ({title, content, active, updateActive}) => {

  const { width } = useWidth(); 

  React.useEffect(() => {
      if(typeof window !== "undefined"){

         window.document.getElementById('App1').href = width < 950  
                                                                          ?
                                                                          "https://apps.apple.com/us/app/kchat-the-key-to-chat-safely/id1510149456"
                                                                          :
                                                                          "https://apps.apple.com/us/app/kchat-the-key-to-chat-safely/id1510149456";
         
         window.document.getElementById('Google1').href = width < 950  
                                                                          ?
                                                                          "https://play.google.com/store/apps/details?id=com.knesis.kchat"
                                                                          :
                                                                          "https://play.google.com/store/apps/details?id=com.knesis.kchat";

      }
  },[width])
  
  return (
    <div className={classes.accordion_item}>
            <div className={classes.accordion_title} onClick={() => updateActive({title, active})} onKeyDown={() => updateActive({title, active})}>
                <div>{title}</div>
                <div> 
                    {active ? 
                                <span className={ClassNames("material-icons", classes.material_icons)}>
                                expand_less
                                </span>
                               : 
                                <span className={ClassNames("material-icons", classes.material_icons)}>
                                expand_more
                                </span>
                                
                    }
                </div>
            </div>
            {active && <div className={classes.accordion_content} dangerouslySetInnerHTML={createMarkup({content})}/>}
    </div>
  )
}

export default ItemAccordion