import React from 'react'

export const useWidth = () => {

  const width = React.useRef(0);

  React.useEffect(() => {
    if(typeof window !== "undefined"){
      width.current = parseInt(window.innerWidth);
      const handleResize = () => (width.current = parseInt(window.innerWidth));
      window.addEventListener('resize', handleResize)
      console.log('hook width:', width);
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  },[]);
  
  return {
     width: width.current
  }
}
