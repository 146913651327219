import React from 'react'
import * as classes from './Main.module.scss';
import SpeedDial from '../SpeedDial/SpeedDial';
import Carousel from '../Carousel/Carousel';
import ItemFeatures from '../Carousel/ItemFeatures';
import ItemAboutKchat from '../Carousel/ItemAboutKchat';
import { useExportList } from '../../hook/ExportList';
import { useWidth } from '../../hook/useWidth';
import { heightCarouselFeature, heightCarouselAboutKchat } from '../../helpers/calculateProperty';
import { StaticImage } from 'gatsby-plugin-image';

const Main = () => {

  const { listItem_Features, listItem_AboutKchat } = useExportList();

  const [Fstate, setFState] = React.useState({
                                            F_height: 0,
                                            F_fontSizeH3: 0,
                                            F_fontSizeP: 0,
                                            F_lineHeight: 0,
                                            F_marginTop: 0,
                                            F_heightDynamic: []
                                          });
  const [Astate, setAState] = React.useState({
                                            A_height: 0,
                                            A_fontSizeH3: 0,
                                            A_fontSizeP: 0,
                                            A_lineHeight: 0,
                                            A_marginTop: 0,
                                            A_heightDynamic: []
                                          });

  const { width } = useWidth(); 

  React.useEffect(() => {
      setFState({
                F_height: heightCarouselFeature({width}).height,
                F_fontSizeH3: heightCarouselFeature({width}).fontSizeH3,
                F_fontSizeP: heightCarouselFeature({width}).fontSizeP,
                F_lineHeight: heightCarouselFeature({width}).lineHeight,
                F_marginTop: width < 950 ? `2rem` : `0rem`,
                F_heightDynamic: width < 950 ? [605, 530, 530] : [400, 400, 400]
              });
      setAState({
                A_height: heightCarouselAboutKchat({width}).height,
                A_fontSizeH3: heightCarouselAboutKchat({width}).fontSizeH3,
                A_fontSizeP: heightCarouselAboutKchat({width}).fontSizeP,
                A_lineHeight: heightCarouselAboutKchat({width}).lineHeight,
                A_marginTop: `0rem`,
                A_heightDynamic: width < 950 ? [390, 360, 320] : [340, 300, 280]
              });
  },[width])

  return (
            <main className={classes.home_main1}>
                <section className={classes.home_main1_speedDial}>
                  <SpeedDial/>
                </section>
                <section className={classes.home_main1_group37}>
                  <div className={classes.main1_Group25}>
                      <StaticImage placeholder= {`none`}  loading={`eager`} src='../../images/desktop/Group_67.png' alt={`iPhone`} className={classes.home_main1_group37_img2} />
                  </div>
                  <div className={classes.main1_section_carouselFeature} id="features">
                      <section className={classes.section_container_title}>
                        <div className={classes.home_group37_title}>
                            <h1 className={classes.home_group37_title_h1}>Features</h1>
                        </div>
                      </section>
                      <div className={classes.Carousel1}>
                      <Carousel 
                                height={Fstate.F_height} 
                                marginTop={Fstate.F_marginTop} 
                                key={`1`}
                                heightDynamic={Fstate.F_heightDynamic}
                      >
                            {
                              listItem_Features.map((item) => <ItemFeatures 
                                                                    listItem={item} 
                                                                    height={Fstate.F_height}
                                                                    fontSizeH3={Fstate.F_fontSizeH3}
                                                                    fontSizeP={Fstate.F_fontSizeP}
                                                                    lineHeight={Fstate.F_lineHeight} 
                                                                    key={item.title3}
                                                              />
                                                    )
                            }
                      </Carousel>
                      </div>
                  </div>
                </section>
                <section  className={classes.home_main1_aboutCkat}>
                  <div className={classes.home_main1_aboutCkat_div}>
                    <span className={classes.home_main1_aboutCkat_h1}>What Users Say About kChat</span>
                  </div>
                  <div className={classes.Carousel2}>
                  <Carousel 
                            height={Astate.A_height}
                            heightDynamic={Astate.A_heightDynamic} 
                            marginTop={Astate.A_marginTop} 
                            key={`2`}>
                        {
                          listItem_AboutKchat.map((item) => <ItemAboutKchat 
                                                                    listItem={item} 
                                                                    height={Astate.A_height}
                                                                    fontSizeH3={Astate.A_fontSizeH3}
                                                                    fontSizeP={Astate.A_fontSizeP}
                                                                    lineHeight={Astate.A_lineHeight}
                                                                    key={item.title}
                                                            />
                                                  )
                        }
                  </Carousel>
                  </div>
                </section>
                <section className={classes.home_main1_gettingCkat}>
                  <div className={classes.Carousel}>
                          <div className={classes.home_main1_gettingCkat_div}>
                            <p className={classes.home_main1_gettingCkat_h1}>Getting started with kChat is quick and easy.</p>
                          </div>
                          <div className={classes.home_main1_gettingCkat_container}>
                              <div className={classes.main_iPhonePro2_container}>
                                <StaticImage placeholder= {`none`}  loading={`eager`} src='../../images/desktop/iPhone12Pro@2x.png'  alt={`iPhone_Pro2`} className={classes.main_iPhonePro2} />
                              </div>
                              <div className={classes.home_main1_gettingCkat_ol}>
                                <ol start="1">
                                  <li>Download kChat on your smartphone or tablet.</li>
                                  <li>Register your parent/guardian account with your phone number.</li>
                                  <li>Create profile(s) for your kid(s).</li>
                                  <li>Create private groups.</li>
                                  <li>Invite friends or accept invitations.</li>
                                  <li>Install kChat on your kid(s) phone(s)</li>
                                  <li>Login with your parent/guardian number</li>
                                  <li style={{ textDecoration: "underline" }}>Kids can start chatting!</li>
                                </ol>
                              </div>
                          </div>    
                  </div>
                </section>
              </main>
  )
}

export default Main