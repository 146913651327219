import React from 'react'
import { classes } from './styles.js'
import { useWidth } from '../../hook/useWidth';

const ItemAboutKchat = ({listItem, height, fontSizeH3, fontSizeP, lineHeight}) => {

  const { width } = useWidth();
  
  return (
        <div style={{
                     ...classes.home_main1_aboutKchat_container, 
                     ...{height: height},
                     ...{
                          marginLeft: width >= 950  ? "auto" : "3rem", 
                          marginRight: width >= 950  ? "auto" : "3rem",
                          width: width >= 950  ? "500px" : "",
                        }
                     }}
          >
            <div style={classes.home_container}>
              <p style={{...classes.home_aboutKchat_p, ...{fontSize: fontSizeP, lineHeight: lineHeight}}}>{listItem.text}</p>
              <p style={{...classes.home_aboutKchat_p, ...{fontSize: fontSizeP, lineHeight: lineHeight}}}>{listItem.author}</p>
            </div>
        </div>
  )
}

export default ItemAboutKchat