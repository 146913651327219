export const classes = {
    home_group37_title_h3: {
                            fontFamily: `'Raleway', sans-serif`,
                            fontStyle: "normal",
                            fontWeight: "500",
                            color: "#000000",
                            padding: "0rem 3rem",
                            },
    home_group37_title_h1: {
                            marginTop: "1rem", 
                            marginBottom: "1rem",
                            fontFamily: `'Raleway', sans-serif`,
                            fontStyle: "normal",
                            fontWeight: "400",
                            display: "flex",
                            alignItems: "center",
                            color: "#BB57FF",
                            },
    home_main1_group37_container: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            backgroundColor: "white",
                            marginLeft: "3rem",
                            marginRight: "3rem",
                            margintTop: "3rem",
                            borderRadius: "10px",
                            },
    home_main1_aboutKchat_container: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            marginLeft: "3rem",
                            marginRight: "3rem",
                            margintTop: "3rem",
                            borderRadius: "20px",
                            },
    home_main1_group37_img: {
                            height: "180px",
                            width: "176px",
                            marginTop: "2px",
                            },
    home_group37_title: {
                        textAlign: "center",
                        marginTop: "1rem",
                        },
    home_group37_p: {
                    fontFamily: `'Raleway', sans-serif`,
                    fontStyle: "normal",
                    fontWeight: "500",
                    color: "#000000",
                    padding: "0rem 3rem",
                    },
    home_container: {
                    flex: "1 1 0",
                    },
    home_fotter_rectangle13_container: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    boxShadow: "0px 3.51638px 9.67004px rgba(98, 66, 0, 0.05)",
                    borderRadius: "20px",
                    margin: "0rem 3rem",
                    paddingRight: "1rem",
                    paddingTop: "1.5rem",
                    backgroundColor: "white",
                    width: "auto",
                    },
    home_footer_img_container: {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    },
    home_footer_p: {
                        fontFamily: `'Raleway', sans-serif`,
                        fontStyle: "normal",
                        fontWeight: "500",
                        color: "#000000",
                        padding: "0rem 2rem",
                        },
    home_fotter_container:  {
                        paddingLeft: "auto",
                        display: "flex",
                        flexDirection: "column",
                        paddingRight: "auto",
                        width: "100%"
                    },
    home_footer_title: {
                        textAlign: "left",
                        width: "100%",
                        },
    home_title_h2 : {
                        width: "auto",
                        fontFamily: `'Raleway', sans-serif`,
                        fontStyle: "normal",
                        fontWeight: "300",
                        fontSize: "24px",
                        lineHeight: "14px",
                        display: "flex",
                        alignItems: "center",
                        color: "#BB57FF",
                    },
    home_footer_card_h3: {
                        width: "auto",
                        fontFamily: `'Raleway', sans-serif`,
                        fontStyle: "normal",
                        fontWeight: "700",
                        color: "#000000",
                        padding: "0rem 2rem",
                    },
    home_footer_card: {
                        textAlign: "left",

                    },
    home_footer_images:  {
                        display: "flex",
                        flexDirection: "row",
                        width: "auto",
                        marginTop: "0.3rem",
                        marginLeft: "auto",
                        marginRight: "auto",
                    },
    home_footer_img: {
                        marginTop: "1.5rem",
                        marginBottom: "1.5rem",
                    },
    home_footer_container: {
                        width: "100%"
                        },
    home_footer_li: {
                    fontFamily: `'Raleway', sans-serif`,
                    fontStyle: "normal",
                    fontWeight: "500",
                    color: "#000000",
                    padding: "0rem 2rem",
                    margin: "0",
                    marginBottom: "0.5rem",
                    },
    home_group37_li: {
                    fontFamily: `'Raleway', sans-serif`,
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "30px",
                    color: "#000000",
                    },
    home_footer_title_h2 : {
                        width: "auto",
                        fontFamily: `'Raleway', sans-serif`,
                        fontStyle: "normal",
                        fontWeight: "300",
                        fontSize: "24px",
                        lineHeight: "14px",
                        display: "flex",
                        alignItems: "left",
                        color: "#BB57FF",
                        padding: "0rem 2rem",
                    },
    home_main1_aboutUs_container: {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        marginLeft: "3rem",
                        marginRight: "3rem",
                        margintTop: "3rem",
                        boxShadow: "0px 3.51638px 9.67004px rgba(98, 66, 0, 0.05)",
                        borderRadius: "20px",
                        },
    home_aboutUs_title_h3: {
                            fontFamily: `'Raleway', sans-serif`,
                            fontStyle: "normal",
                            fontWeight: "300",
                            lineHeight: "30px",
                            color: "#000000",
                            padding: "0rem 2rem",
                            margin: "2rem 0rem",
                            },
    home_aboutKchat_p: {
                        fontFamily: `'Raleway', sans-serif`,
                        fontStyle: "normal",
                        fontWeight: "500",
                        color: "#000000",
                        padding: "0rem 1.5rem",
                        },
};