import React from 'react';
import * as classes from './Header.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import Nav from '../Nav/Nav';
import { Link } from 'gatsby';
import { useWidth } from '../../hook/useWidth';

const Header = () => {

  const { width } = useWidth();
  
  return (
        <header className={classes.home_header}>
                <section className={classes.home_backg_logo }>
                  <Nav />
                  <Link to={`/`} className={classes.home_backg_link }>
                    <StaticImage placeholder= {`none`}  loading={`eager`} src='../../images/mobile/App_Logo@3x.png' alt="App_Logo" className={classes.home_img} />
                  </Link>
                </section>
                <section className={classes.home_header_divider}></section>
                <section className={classes.home_backg_rectangle3}>
                  <div className={classes.home_rect_container}>
                      <div className={classes.home_rectangle3_container}>
                        <h1 className={classes.home_title_h1}>
                          Keeping Kids Safe and Connected.
                        </h1>
                        <p className={classes.home_rectangle3_text}>
                          Fun messaging made just for kids by parents who put safety first.
                        </p>
                      </div>
                        <div className={classes.home_chat}>
                          <Link 
                               className={classes.home_chat_p}
                               to={
                                     width > 950  
                                                  ?
                                                  "/download"
                                                  :
                                                  ""
                               }
                          >
                            Chat Now!
                          </Link>
                          <div className={classes.home_chat_img_container}>
                            <a 
                                href={ 
                                      width < 950  
                                                  ?
                                                  "https://apps.apple.com/us/app/kchat-the-key-to-chat-safely/id1510149456"
                                                  :
                                                  "https://apps.apple.com/us/app/kchat-the-key-to-chat-safely/id1510149456"
                                    }
                                alt="App Store">
                              <StaticImage placeholder= {`none`} loading={`eager`} src='../../images/mobile/App_Store.svg' alt="App Store" className={classes.home_chat_img1} />
                            </a>
                            <a 
                                href={ 
                                  width < 950  
                                              ?
                                              "https://play.google.com/store/apps/details?id=com.knesis.kchat"
                                              :
                                              "https://play.google.com/store/apps/details?id=com.knesis.kchat"
                                    }
                                  alt="Google Play">
                            <StaticImage placeholder= {`none`}  loading={`eager`} src='../../images/mobile/google-play-badge.png' alt="Google Play" className={classes.home_chat_img}/>
                            </a>
                          </div>
                        </div>
                   </div>
                <div className={classes.home_container_img_boys}>
                    <StaticImage placeholder= {`none`}  loading={`eager`} src='../../images/desktop/Group_43.png' alt="boys" className={classes.home_img_boys}/>
                </div>           
                </section>
        </header>
  )
}

export default Header