import React from 'react';
import * as classes from './Footer.module.scss';
import ItemWhyKchat from '../Carousel/ItemWhyKchat';
import Carousel from '../Carousel/Carousel';
import { useExportList } from '../../hook/ExportList';
import { useWidth } from '../../hook/useWidth';
import { heightCarouselWhyKchat } from '../../helpers/calculateProperty';

const Footer = () => {

  const { listItem_WhyKchat } = useExportList();

  const { width } = useWidth();

  const [Wstate, setWState] = React.useState({
                                              W_height: 0,
                                              W_section: {},
                                              W_div1: {},
                                              W_marginTop: 0,
                                              W_heightDynamic: []
                                            });
      React.useEffect(() => {
        setWState({
                  W_height: heightCarouselWhyKchat({width}).height,
                  W_section: heightCarouselWhyKchat({width}).section,
                  W_div1: heightCarouselWhyKchat({width}).div1,
                  W_marginTop: `1rem`,
                  W_heightDynamic: width <= 450 ? [850, 870] : (width < 800 ? [850, 870] : ( width >= 1000 ? [720, 720] : [980, 960]))
                });
    },[width])

  return (
          <footer className={classes.home_fotter_rectangle13} id="whykchat">
            <section className={classes.home_fotter_container_all}>
              <section className={classes.home_fotter_container}>
                        <div className={classes.home_footer_title}>
                          <h2 className={classes.home_footer_title_h2}>Why kChat App?</h2>
                        </div>
              </section>
              <div className={classes.Carousel}>
              <Carousel 
                      height={Wstate.W_height} 
                      heightDynamic={ Wstate.W_heightDynamic} 
                      marginTop={Wstate.W_marginTop} 
                      key={`3`}
                >
                {
                  listItem_WhyKchat.map((item) => <ItemWhyKchat 
                                                          listItem={item} 
                                                          height={Wstate.W_height}
                                                          section={Wstate.W_section}
                                                          div1={Wstate.W_div1}
                                                          key={item.text}/>)
                }
              </Carousel>
              </div>
            </section>
          </footer>
  )
}

export default Footer