import React from 'react';
import Seo from '../components/Seo/Seo';

import app from '../firebase/firebase';
import { getAnalytics, logEvent } from "firebase/analytics";

import Header from '../components/Header/Header';
import Main from '../components/Main/Main';
import Footer from '../components/Footer/Footer';
import Main2 from '../components/Main2/Main2';
import Footer2 from '../components/Footer2/Footer2';
import Scroll from '../components/Scroll/Scroll';
import './index.scss';


// markup
const IndexPage = () => {

  React.useEffect(() => {
    if (!app) {
      return
    }
    
    const analytics = getAnalytics(app);
    logEvent(analytics, 'visited_home');
  }, [])

  return (
    <>
          <Scroll />
          <Seo title={`Home`} />
          <div className="div2">
              <Header/>
              <Main />
              <Footer />
           </div>
           <div className="div1">
             <Main2 />
             <Footer2 />
           </div>
    </>
  )
}

export default IndexPage
