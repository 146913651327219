import React from 'react';
import Carousel from 'react-material-ui-carousel'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import { useExportList } from '../../hook/ExportList';
import { useWidth } from '../../hook/useWidth';

const CarouselComp = ({ marginTop, swipe = false, animation = "slide", navButtonsAlwaysVisible = true, indicators = false, 
                        autoPlay = false, height, heightDynamic, fullHeightHover = false, 
                        activeTitle = false, setTitleFAQs, children }) => {
 
  const ref = React.useRef(null);

  const { width } = useWidth();

  const [heightD, setHeightD] = React.useState(0);

  const { listItem_AccordionData } = useExportList();

  React.useEffect(() => {
    console.log('heightD:', heightD);
    console.log('heightDynamic', heightDynamic);
  }, [heightD, heightDynamic]);  
  
  const navButtonFunt = ({onClick, className, style, next, prev}) => {
        // Add new styles
        const sx={
          backgroundColor: "transparent",
          width: "auto",
          height: "auto",
          borderRadius: "50%",
          '&:hover': {
            backgroundColor: "transparent !important",
          },
        };

        const styles = {...style, ...sx};

        return (
            <Button onClick={onClick} className={className} 
                    style={styles} 
                    name={ next ? `button-next` : `button-prev`}
            >
                {next && <ArrowForwardIosIcon 
                                      sx={{ 
                                            color: "#F4B028",
                                            fontSize: width < 1250  ? "2.5rem" : "3.5rem", 
                                            '&:hover': {
                                              color: "red",
                                            }
                                          }} 
                          />
                }
                {prev && <ArrowBackIosIcon 
                                        sx={{ 
                                            color: "#F4B028",
                                            fontSize: width < 1250  ? "2.5rem" : "3.5rem", 
                                            marginLeft: "0.6rem",
                                            '&:hover': {
                                              color: "red",
                                            }
                                          }} 
                          />
                }
            </Button>
        )
    };

   

    const nextSlider = (next, active) => {
                                            setHeightD(() => {
                                              if(heightDynamic?.length > 0){
                                                return heightDynamic[next];
                                              }
                                              else {
                                                return 0
                                              } 
                                            }
                                              )
                                            if(activeTitle){
                                              setTitleFAQs(listItem_AccordionData[next][0].title1)
                                            }
                                            
                                            
                                          };

    const prevSlider = (prev, active) => {
                                            setHeightD(() => {
                                              if(heightDynamic?.length > 0){
                                                return heightDynamic[prev];
                                              }
                                              else {
                                                return 0
                                              } 
                                            }
                                              )
                                            if(activeTitle){
                                              setTitleFAQs(listItem_AccordionData[prev][0].title1)
                                            }
                                            
                                            
                                          };

  return (
      <div ref={ref}>
          <Carousel
                  navButtonsAlwaysVisible={navButtonsAlwaysVisible}
                  sx={{ marginTop: marginTop,}}
                  animation={animation}
                  indicators={indicators}
                  autoPlay={autoPlay}
                  height={(heightD === 0) ? height : heightD}
                  swipe={swipe}
                  fullHeightHover={fullHeightHover}     // We want the nav buttons wrapper to only be as big as the button element is
                  navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                      style: {
                          backgroundColor: 'transparent',
                          position: "absolute",
                          height: "auto",
                          top: "calc(50% - 70px)",
                          opacity: "1",
                          '& :hover button': {
                            backgroundColor: "transparent!important",
                          },
                      }
                  }}
                NavButton={navButtonFunt}
                next={ nextSlider }
                prev={ prevSlider }
              
              >
                  {
                      children
                  }
              </Carousel>
        </div>
  )
}

export default CarouselComp