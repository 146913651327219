import * as React from 'react';

import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon, Backdrop, Avatar} from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import * as classes from './SpeedDial.module.scss';

import Chat from '../../images/icons/Icons_Chat.png';
import Start from '../../images/icons/Icons@2x3_Start.png';
import Global from '../../images/icons/Icons@2x4_global.png';
import Camera from '../../images/icons/Icons2_Camera.png';
import Invite from '../../images/icons/Invite@2x.png';

const CustomizedSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const CustomizedSpeedDialAction = styled(SpeedDialAction)(({ theme }) => ({
  '& .MuiSpeedDialAction-staticTooltipLabel' : {
    width: theme.spacing(10),
  },
}));



export default function BasicSpeedDial() {
  const [open, setOpen] = React.useState(false);

  const actions = [
    { icon: <AnchorLink to={`/#features`} stripHash ><Avatar src={Chat}  alt="Features" key={`1`}/></AnchorLink>, name: <AnchorLink to={`/#features`} stripHash  className={classes.link} key={`2`}>FEATURES</AnchorLink>},
    { icon: <AnchorLink to={`/#whykchat`} stripHash ><Avatar src={Start}  alt="Why Kchat App?" key={`3`}/></AnchorLink>, name: <AnchorLink to={`/#whykchat`} stripHash  className={classes.link} key={`4`}>WHY KCHAT APP?</AnchorLink>},
    { icon: <AnchorLink to={`/#faq`} stripHash ><Avatar src={Invite}  alt="FAQ" key={`5`}/></AnchorLink>, name: <AnchorLink to={`/#faq`} stripHash  className={classes.link} key={`6`}>FAQ</AnchorLink>},
    { icon: <AnchorLink to={`/#download`}><Avatar src={Chat}  alt="Download" key={`7`}/></AnchorLink>, name: <AnchorLink to={`/#download`} stripHash  className={classes.link} key={`8`}>DOWNLOAD</AnchorLink> },
    { icon: <Link to={`/about`} key={`9`}><Avatar src={Start}  alt="About Us"/></Link>, name: <Link to={`/about`} className={classes.link} key={`10`}>ABOUT US</Link>},
    { icon: <Link to={`/privacy`} key={`11`}><Avatar src={Global}  alt="Privacy Policy" /></Link>, name: <Link to={`/privacy`} className={classes.link} key={`12`}>PRIVACY POLICY</Link> },
    { icon: <Link to={`/terms`} key={`13`}><Avatar src={Camera}  alt="Terms of Use" /></Link>, name: <Link to={`/terms`} className={classes.link} key={`14`}>TERMS OF USE</Link>}, 
  ];

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
    <Backdrop 
          open={open} 
          sx={{ color: '#fff', width: "100%", height: "100vh", 
                backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "10"}}
      />
    <Box sx={{ 
               height: 320, transform: 'translateZ(0px)', 
               flexGrow: 1, position: "absolute", top: "-10px", 
               left: "0px",  zIndex: "20",
               '& .MuiSpeedDialAction-staticTooltipLabel' : {
                width: "15rem",
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
                color: "white",
                marginLeft: "-1.6rem !important",
                '& .MuiSpeedDialAction-staticTooltipLabel > a' : {
                  textDecoration: "none",
                  color: "white"
                },
              },
               }}>
      <CustomizedSpeedDial
        ariaLabel="SpeedDial basic example" 
        icon={<SpeedDialIcon icon={<MenuIcon sx={{fontSize: "35px"}}/>}  openIcon={<CloseIcon sx={{fontSize: "35px"}}/>} />}
        onClick={handleClick}
        open={open}
        direction="down"
        FabProps={{style: { backgroundColor: "transparent", border: "none", boxShadow: "none", zIndex: "30" }}}
      >
        {actions.map((action, index) => (
          <CustomizedSpeedDialAction
            key={index}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen={true}
            tooltipPlacement="right"
            sx={{ width: "50px", height: "50px", background: "transparent", border: "none", boxShadow: "none", zIndex: "30"}}
          />
        ))}
      </CustomizedSpeedDial>
    </Box>
    </>
  );
}