import React from 'react';
import Accordion from '../Accordion/Accordion';
import * as classes from './Main2.module.scss';
import Carousel from '../Carousel/Carousel';
import { useExportList } from '../../hook/ExportList';
import { useWidth } from '../../hook/useWidth';
import { heightCarouselAccordion } from '../../helpers/calculateProperty'; 
import { StaticImage } from 'gatsby-plugin-image';

const Main2 = () => {

    const { listItem_AccordionData } = useExportList();

    const { width } = useWidth(); 

    const [titleFAQs, setTitleFAQs] = React.useState('Using kChat');

    const [Astate, setWState] = React.useState({
                                                A_height: 0,
                                                A_marginTop: 0,
                                                A_heightDynamic: []
                                            });
    React.useEffect(() => {
        setWState({
        A_height: heightCarouselAccordion({width}).height,
        A_marginTop: `0rem`,
        A_heightDynamic: width <= 375 ? [920, 530, 320, 480] : (width <= 450 ? [840, 530, 320, 480] : (width <= 950 ? [920, 530, 320, 480] : (width <= 1550 ? [780, 780, 780, 780] : [850, 850, 850, 850])))
        });
    },[width])
    
  return (
    <>
        <main className={classes.home2_main2}>
            <div className={classes.Carousel}>
            <section style={{textAlign: "center", marginTop: "6rem"}} id="faq">
                <h2 className={classes.home2_title_h2}> Have a question about kChat? </h2>
            </section>
            <section style={{textAlign: "center"}} className={classes.home2_section_title}>
                <div className={classes.home2_div_center}></div>
                <p className={classes.home2_title_p}>{titleFAQs}</p>
            </section>
            <section className={classes.home2_main2_section}>
                <Carousel  
                        height={ Astate.A_height} 
                        marginTop={Astate.A_marginTop} 
                        key={`8`} 
                        activeTitle={true} 
                        setTitleFAQs={setTitleFAQs}
                        heightDynamic={Astate.A_heightDynamic} 
                >
                {
                    listItem_AccordionData.map((item) => (<Accordion initialAccordionData={item} key={item[0].title1} />))
                }
                </Carousel>
                <section className={classes.home2_img}>
                    <StaticImage placeholder= {`none`}  loading={`lazy`} src='../../images/mobile/iPhone-12-Pro.png' alt={`family`} className={classes.home2_img1}/>
                    <StaticImage placeholder= {`none`}  loading={`lazy`}  src='../../images/desktop/iPhone12Pro-Desktop.svg' alt={`family`} className={classes.home2_img2}/>
                </section>
            </section>
            </div>
        </main>
    </>
  )
}

export default Main2